.page-top .mainvisual {
  display: block;
  width: 100%;
  height: 628px;
  background-image: url(../img/top/mainvisual/grid.svg);
  background-position: left top -7.2px;
  background-repeat: repeat;
  background-size: 30px auto;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual {
    height: 570px;
    background-position: left top;
    background-size: 26px auto;
  }
}
.page-top .mainvisual_inner {
  display: block;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_inner {
    min-width: 375px;
  }
}
.page-top .mainvisual_inner::before, .page-top .mainvisual_inner::after {
  content: "";
  display: block;
  width: 600px;
  height: auto;
  aspect-ratio: 1/0.867;
  position: absolute;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
@media screen and (min-width: 1500px) {
  .page-top .mainvisual_inner::before, .page-top .mainvisual_inner::after {
    width: min(40vw, 840px);
  }
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_inner::before, .page-top .mainvisual_inner::after {
    width: max(61.4vw - 20px, 230px);
    max-width: 330px;
  }
}
.page-top .mainvisual_inner::before {
  background-image: url(../img/top/mainvisual/left.png);
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_inner::before {
    left: -48px;
  }
}
.page-top .mainvisual_inner::after {
  background-image: url(../img/top/mainvisual/right.png);
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_inner::after {
    right: -59px;
  }
}
.page-top .mainvisual_title {
  display: block;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.044em;
  writing-mode: vertical-rl;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_title {
    top: 35px;
    font-size: 2.4rem;
    line-height: 1.2916666667;
    letter-spacing: 0.17em;
  }
}
.page-top .mainvisual_text {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 68px;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_text {
    width: calc(100% - 75px);
    min-width: 300px;
    bottom: 51px;
  }
}
.page-top .mainvisual_text > :first-child {
  margin-top: 0;
}
.page-top .mainvisual_text_paragraph {
  margin: 5px 0 0;
  text-align: center;
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_text_paragraph {
    font-size: 1.6rem;
    line-height: 1.875;
    margin: 0;
  }
}
.page-top .mainvisual_text_paragraph_number {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_text_paragraph_number {
    font-size: 1.7rem;
  }
}
.page-top .mainvisual-js .mainvisual_inner::before, .page-top .mainvisual-js .mainvisual_inner::after {
  opacity: 0;
  visibility: hidden;
  top: 60px;
  transition: all 0.4s ease;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual-js .mainvisual_inner::before, .page-top .mainvisual-js .mainvisual_inner::after {
    top: 265px;
  }
}
.page-top .mainvisual-js .mainvisual_inner::before {
  left: -100px;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual-js .mainvisual_inner::before {
    left: -78px;
  }
}
.page-top .mainvisual-js .mainvisual_inner::after {
  right: -100px;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual-js .mainvisual_inner::after {
    right: -89px;
  }
}
.page-top .mainvisual-js-active .mainvisual_inner::before, .page-top .mainvisual-js-active .mainvisual_inner::after {
  top: 40px;
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual-js-active .mainvisual_inner::before, .page-top .mainvisual-js-active .mainvisual_inner::after {
    top: 245px;
  }
}
.page-top .mainvisual-js-active .mainvisual_inner::before {
  left: -50px;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual-js-active .mainvisual_inner::before {
    left: -68px;
  }
}
.page-top .mainvisual-js-active .mainvisual_inner::after {
  right: -50px;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual-js-active .mainvisual_inner::after {
    right: -59px;
  }
}
.page-top .service {
  display: block;
  width: 100%;
  padding: 130px 0 261px;
  background-color: #f8f8f8;
}
@media screen and (max-width: 767px) {
  .page-top .service {
    padding: 72px 0;
  }
}
.page-top .service_title {
  display: flex;
  align-items: center;
}
.page-top .service_title_en {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-weight: 400;
  color: #214da6;
  padding-right: 36px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-top .service_title_en {
    font-size: 1.2rem;
    line-height: 1.4166666667;
    padding-right: 21px;
  }
}
.page-top .service_title_en::after {
  content: "";
  display: block;
  width: 16px;
  height: 1px;
  background-color: #9a9a9a;
  transform: rotate(-68deg);
  position: absolute;
  top: 11.5px;
  right: 8px;
}
@media screen and (max-width: 767px) {
  .page-top .service_title_en::after {
    width: 10px;
    top: 8px;
    right: 5px;
  }
}
.page-top .service_title_ja {
  font-size: 1.6rem;
  line-height: 1.8125;
  font-weight: 500;
  color: #9a9a9a;
}
@media screen and (max-width: 767px) {
  .page-top .service_title_ja {
    font-size: 1.2rem;
    line-height: 1.4166666667;
  }
}
.page-top .service_head {
  display: flex;
  column-gap: 60px;
}
@media screen and (max-width: 767px) {
  .page-top .service_head {
    flex-direction: column;
    column-gap: 0;
    row-gap: 24px;
  }
}
.page-top .service_head_title {
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 1.5789473684;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .page-top .service_head_title {
    white-space: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .page-top .service_head_title {
    font-size: 2.2rem;
    line-height: 1.5;
  }
}
.page-top .service_head_description {
  font-size: 1.6rem;
  line-height: 2;
  margin: 9px 0 0;
}
@media screen and (max-width: 767px) {
  .page-top .service_head_description {
    font-size: 1.3rem;
    line-height: 2;
    margin: 0;
  }
}
.page-top .service small {
  font-size: 1.3rem;
  line-clamp: 1.3846153846;
}
@media screen and (max-width: 767px) {
  .page-top .service small {
    font-size: 1.3rem;
    line-clamp: 1.6923076923;
  }
}
.page-top .service_list {
  width: 100%;
  margin-top: 81px;
}
@media screen and (min-width: 768px) {
  .page-top .service_list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    column-gap: 1px;
    row-gap: 1px;
  }
}
@media screen and (max-width: 767px) {
  .page-top .service_list {
    display: block;
    margin-top: 40px;
  }
}
.page-top .service_list.scrollAnimation-js .service_list_item {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}
.page-top .service_list.scrollAnimation-js .service_list_item:nth-of-type(2) {
  transition-delay: 0.3s;
}
.page-top .service_list.scrollAnimation-js .service_list_item:nth-of-type(3) {
  transition-delay: 0.6s;
}
.page-top .service_list.scrollAnimation-js .service_list_item:nth-of-type(4) {
  transition-delay: 0.9s;
}
.page-top .service_list.scrollAnimation-js .service_list_item:nth-of-type(5) {
  transition-delay: 1.2s;
}
.page-top .service_list.scrollAnimation-js .service_list_item:nth-of-type(6) {
  transition-delay: 1.5s;
}
.page-top .service_list.scrollAnimation-js-active .service_list_item {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.page-top .service_list_item {
  display: block;
  width: calc(33.3333333333% - 0.6666666667px);
  background-color: #ffffff;
}
@media screen and (max-width: 767px) {
  .page-top .service_list_item {
    width: 100%;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .page-top .service_list_item:nth-of-type(2n) {
    position: relative;
    top: 60px;
  }
}
@media screen and (min-width: 768px) {
  .page-top .service_list_item:nth-of-type(3n) {
    position: relative;
    top: 120px;
  }
}
.page-top .service_list_item_image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/0.694;
}
@media screen and (max-width: 767px) {
  .page-top .service_list_item_image {
    aspect-ratio: 1/0.55;
  }
}
.page-top .service_list_item_image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-top .service_list_item_text {
  display: block;
  width: 100%;
  padding: 28px 28px 50px;
}
@media screen and (max-width: 767px) {
  .page-top .service_list_item_text {
    padding: 20px 24px 26px;
  }
}
.page-top .service_list_item_text_title {
  font-size: 2.4rem;
  line-height: 1.5;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .page-top .service_list_item_text_title {
    font-size: 2rem;
    line-height: 1.3;
  }
}
.page-top .service_list_item_text_description {
  font-size: 1.6rem;
  line-height: 1.4375;
  margin: 15px 0 0;
}
@media screen and (max-width: 767px) {
  .page-top .service_list_item_text_description {
    font-size: 1.3rem;
    line-height: 1.6923076923;
    margin: 12px 0 0;
  }
}
.page-top .service_list_item_text_list {
  font-size: 1.6rem;
  line-height: 1.4375;
  margin: 20px 0 0;
  padding: 0 0 0 1.2em;
}
@media screen and (max-width: 767px) {
  .page-top .service_list_item_text_list {
    font-size: 1.3rem;
    line-height: 1.6923076923;
    margin: 14px 0 0;
  }
}
.page-top .service_list_item_text_list_item {
  margin-top: 2px;
}
.page-top .separator {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/0.412;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .page-top .separator {
    aspect-ratio: 1/0.66;
    background-image: url(../img/top/para.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.page-top .separator::after {
  content: "";
  display: block;
  width: 354px;
  height: auto;
  aspect-ratio: 1/1.167;
  background-image: url(../img/top/symbol.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  right: 59px;
  bottom: -271px;
}
@media screen and (max-width: 767px) {
  .page-top .separator::after {
    width: 137px;
    right: 18px;
    bottom: -105px;
  }
}
.page-top .separator_inner {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .page-top .separator_inner {
    display: none;
  }
}
.page-top .separator_image {
  display: block;
  width: 100%;
}
.page-top .features {
  display: block;
  width: 100%;
  padding: 139px 0 136px;
  background-color: #f8f8f8;
}
@media screen and (max-width: 767px) {
  .page-top .features {
    padding: 102px 0 40px;
  }
}
.page-top .features_title {
  display: flex;
  align-items: center;
}
.page-top .features_title_en {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-weight: 400;
  color: #214da6;
  padding-right: 36px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-top .features_title_en {
    font-size: 1.2rem;
    line-height: 1.4166666667;
    padding-right: 21px;
  }
}
.page-top .features_title_en::after {
  content: "";
  display: block;
  width: 16px;
  height: 1px;
  background-color: #9a9a9a;
  transform: rotate(-68deg);
  position: absolute;
  top: 11.5px;
  right: 8px;
}
@media screen and (max-width: 767px) {
  .page-top .features_title_en::after {
    width: 10px;
    top: 8px;
    right: 5px;
  }
}
.page-top .features_title_ja {
  font-size: 1.6rem;
  line-height: 1.8125;
  font-weight: 500;
  color: #9a9a9a;
}
@media screen and (max-width: 767px) {
  .page-top .features_title_ja {
    font-size: 1.2rem;
    line-height: 1.4166666667;
  }
}
.page-top .features_head {
  display: flex;
  column-gap: 60px;
}
@media screen and (max-width: 767px) {
  .page-top .features_head {
    flex-direction: column;
    column-gap: 0;
    row-gap: 24px;
  }
}
.page-top .features_head_title {
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 1.5789473684;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .page-top .features_head_title {
    white-space: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .page-top .features_head_title {
    font-size: 2.2rem;
    line-height: 1.5;
  }
}
.page-top .features_head_description {
  font-size: 1.6rem;
  line-height: 2;
  margin: 9px 0 0;
}
@media screen and (max-width: 767px) {
  .page-top .features_head_description {
    font-size: 1.3rem;
    line-height: 2;
    margin: 0;
  }
}
.page-top .features_list {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  width: 100%;
  max-width: 1080px;
  margin: 100px auto 0;
}
@media screen and (max-width: 767px) {
  .page-top .features_list {
    margin-top: 30px;
    row-gap: 1px;
  }
}
.page-top .features_list.scrollAnimation-js .features_list_item {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}
.page-top .features_list.scrollAnimation-js .features_list_item:nth-of-type(2) {
  transition-delay: 0.3s;
}
.page-top .features_list.scrollAnimation-js .features_list_item:nth-of-type(3) {
  transition-delay: 0.6s;
}
.page-top .features_list.scrollAnimation-js .features_list_item:nth-of-type(4) {
  transition-delay: 0.9s;
}
.page-top .features_list.scrollAnimation-js .features_list_item:nth-of-type(5) {
  transition-delay: 1.2s;
}
.page-top .features_list.scrollAnimation-js .features_list_item:nth-of-type(6) {
  transition-delay: 1.5s;
}
.page-top .features_list.scrollAnimation-js-active .features_list_item {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.page-top .features_list_item {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
}
.page-top .features_list_item_inner {
  display: flex;
  width: 100%;
  position: relative;
  color: inherit;
  text-decoration: none;
}
.page-top .features_list_item_inner::before {
  content: "";
  display: block;
  width: 10px;
  height: auto;
  aspect-ratio: 1/1;
  background-image: url(../img/common/arrow_b.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
  top: 50%;
  right: 63px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_inner::before {
    width: 8px;
    background-image: url(../img/common/arrow_w.svg);
    top: auto;
    right: 40px;
    bottom: 39px;
    z-index: 2;
    transform: none;
  }
}
.page-top .features_list_item_inner::after {
  content: "";
  display: block;
  width: 56px;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 100%;
  box-sizing: border-box;
  border: 2px solid #dbdbdb;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_inner::after {
    width: 40px;
    top: auto;
    right: 24px;
    bottom: 23px;
    z-index: 2;
    transform: none;
  }
}
@media screen and (min-width: 768px) {
  .page-top .features_list_item_inner:hover::after {
    width: 62px;
    right: 37px;
    border-width: 1px;
    border-color: #214da6;
  }
}
.page-top .features_list_item_image {
  display: block;
  width: 360px;
  height: auto;
  aspect-ratio: 1/0.667;
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.page-top .features_list_item_image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-top .features_list_item_text {
  display: block;
  width: calc(100% - 360px);
  padding: 46px 127px 53px 50px;
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_text {
    width: 100%;
    padding: 56px 44px 68px;
    position: relative;
    z-index: 1;
    color: #ffffff;
  }
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_text::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #16284d;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.page-top .features_list_item_text_head {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
}
.page-top .features_list_item_text_head_number {
  display: block;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .page-top .features_list_item_text_head_number {
    color: #214da6;
  }
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_text_head_number {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.page-top .features_list_item_text_head_title {
  font-size: 2.8rem;
  line-height: 1.4642857143;
  margin: 0;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_text_head_title {
    font-size: 2rem;
    line-height: 1.3;
  }
}
.page-top .features_list_item_text_description {
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 16px 0 0;
}
@media screen and (max-width: 767px) {
  .page-top .features_list_item_text_description {
    font-size: 1.3rem;
    line-height: 1.6923076923;
    margin: 12px 0 0;
  }
}
.page-top .features .main_buttonLink {
  text-align: center;
  margin-top: 76px;
}
@media screen and (max-width: 767px) {
  .page-top .features .main_buttonLink {
    margin-top: 51px;
  }
}
.page-top .works {
  display: block;
  width: 100%;
  padding: 120px 0 98px;
}
@media screen and (max-width: 767px) {
  .page-top .works {
    padding: 92px 0 50px;
  }
}
.page-top .works_title {
  display: flex;
  align-items: center;
}
.page-top .works_title_en {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-weight: 400;
  color: #214da6;
  padding-right: 36px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-top .works_title_en {
    font-size: 1.2rem;
    line-height: 1.4166666667;
    padding-right: 21px;
  }
}
.page-top .works_title_en::after {
  content: "";
  display: block;
  width: 16px;
  height: 1px;
  background-color: #9a9a9a;
  transform: rotate(-68deg);
  position: absolute;
  top: 11.5px;
  right: 8px;
}
@media screen and (max-width: 767px) {
  .page-top .works_title_en::after {
    width: 10px;
    top: 8px;
    right: 5px;
  }
}
.page-top .works_title_ja {
  font-size: 1.6rem;
  line-height: 1.8125;
  font-weight: 500;
  color: #9a9a9a;
}
@media screen and (max-width: 767px) {
  .page-top .works_title_ja {
    font-size: 1.2rem;
    line-height: 1.4166666667;
  }
}
.page-top .works_head {
  display: flex;
  column-gap: 60px;
}
@media screen and (max-width: 767px) {
  .page-top .works_head {
    flex-direction: column;
    column-gap: 0;
    row-gap: 24px;
  }
}
.page-top .works_head_title {
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 1.5789473684;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .page-top .works_head_title {
    white-space: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .page-top .works_head_title {
    font-size: 2.2rem;
    line-height: 1.5;
  }
}
.page-top .works_head_description {
  font-size: 1.6rem;
  line-height: 2;
  margin: 9px 0 0;
}
@media screen and (max-width: 767px) {
  .page-top .works_head_description {
    font-size: 1.3rem;
    line-height: 2;
    margin: 0;
  }
}
.page-top .works_kind {
  display: flex;
  width: 100%;
  border: 1px solid #dbdbdb;
  padding: 24px 39px 26px 39px;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind {
    flex-direction: column;
    padding: 19px 15px;
    margin-top: 40px;
  }
}
.page-top .works_kind.scrollAnimation-js, .page-top .works_kind.scrollAnimation-js + .main_worksList {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}
.page-top .works_kind.scrollAnimation-js-active, .page-top .works_kind.scrollAnimation-js-active + .main_worksList, .page-top .works_kind.scrollAnimation-js + .main_worksList-active, .page-top .works_kind.scrollAnimation-js + .main_worksList-active + .main_worksList {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.page-top .works_kind.scrollAnimation-js + .main_worksList {
  transition-delay: 0.3s;
}
.page-top .works_kind_head {
  display: flex;
  align-items: center;
  width: 97px;
}
@media screen and (min-width: 768px) {
  .page-top .works_kind_head {
    border-right: 1px solid #dbdbdb;
  }
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_head {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid #dbdbdb;
  }
}
.page-top .works_kind_head_title {
  font-size: 1.4rem;
  line-height: 2.0714285714;
  font-weight: 500;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_head_title {
    font-size: 1.2rem;
    line-height: 1.8333333333;
  }
}
.page-top .works_kind_content {
  display: block;
  width: calc(100% - 97px);
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content {
    width: 100%;
    padding-top: 12px;
  }
}
.page-top .works_kind_content_list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  margin: 0;
  padding: 0 0 0 40px;
  list-style: none;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content_list {
    flex-direction: column;
    column-gap: 0;
    row-gap: 4px;
    padding: 0;
  }
}
.page-top .works_kind_content_list_item {
  display: flex;
  column-gap: 20px;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content_list_item {
    width: 100%;
    flex-direction: column;
    column-gap: 0;
    row-gap: 0;
  }
}
@media screen and (min-width: 768px) {
  .page-top .works_kind_content_list_item:nth-of-type(odd) {
    width: calc(50% - 60px);
  }
}
@media screen and (min-width: 768px) {
  .page-top .works_kind_content_list_item:nth-of-type(odd) .works_kind_content_list_item_title {
    min-width: 82px;
  }
}
@media screen and (min-width: 768px) {
  .page-top .works_kind_content_list_item:nth-of-type(even) {
    width: calc(50% + 20px);
  }
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content_list_item:nth-of-type(even) {
    order: 2;
  }
}
@media screen and (min-width: 768px) {
  .page-top .works_kind_content_list_item:nth-of-type(even) .works_kind_content_list_item_title {
    min-width: 53px;
  }
}
.page-top .works_kind_content_list_item_title {
  font-size: 1.4rem;
  line-height: 1.7142857143;
  font-weight: 500;
  margin: 0;
  padding: 0 0 0 9px;
  white-space: nowrap;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content_list_item_title {
    font-size: 1.2rem;
    line-height: 1.5;
    padding-left: 12px;
  }
}
.page-top .works_kind_content_list_item_title::before {
  content: "";
  display: block;
  width: 4px;
  height: auto;
  aspect-ratio: 1/1;
  background-color: #15181f;
  position: absolute;
  top: 10px;
  left: 0;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content_list_item_title::before {
    top: 6px;
  }
}
.page-top .works_kind_content_list_item_content {
  display: block;
  font-size: 1.4rem;
  line-height: 1.7142857143;
}
@media screen and (max-width: 767px) {
  .page-top .works_kind_content_list_item_content {
    font-size: 1.2rem;
    line-height: 1.5;
    padding-left: 12px;
  }
}
.page-top .works .main_worksList {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .page-top .works .main_worksList {
    margin-top: 40px;
  }
}
.page-top .works .main_buttonLink {
  text-align: center;
  margin-top: 107px;
}
@media screen and (max-width: 767px) {
  .page-top .works .main_buttonLink {
    margin-top: 51px;
  }
}
.page-top .news {
  display: block;
  width: 100%;
  padding: 98px 0 0;
}
@media screen and (max-width: 767px) {
  .page-top .news {
    padding: 50px 0 0;
  }
}
.page-top .news_inner {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .page-top .news_inner {
    flex-direction: column;
    row-gap: 40px;
  }
}
.page-top .news_head {
  display: block;
  width: 360px;
}
@media screen and (max-width: 767px) {
  .page-top .news_head {
    width: 100%;
  }
}
.page-top .news_head_en {
  display: block;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #214da6;
}
@media screen and (max-width: 767px) {
  .page-top .news_head_en {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2px;
  }
}
.page-top .news_head_ja {
  font-size: 3.6rem;
  line-height: 1;
  font-weight: 700;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .page-top .news_head_ja {
    font-size: 2rem;
    line-height: 1.45;
  }
}
.page-top .news_content {
  display: block;
  width: calc(100% - 360px);
}
@media screen and (max-width: 767px) {
  .page-top .news_content {
    width: 100%;
  }
}
.page-top .news_content_list {
  display: block;
  width: 100%;
}
.page-top .news_content_list.scrollAnimation-js .news_content_list_item {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}
.page-top .news_content_list.scrollAnimation-js .news_content_list_item:nth-of-type(2) {
  transition-delay: 0.3s;
}
.page-top .news_content_list.scrollAnimation-js .news_content_list_item:nth-of-type(3) {
  transition-delay: 0.6s;
}
.page-top .news_content_list.scrollAnimation-js .news_content_list_item:nth-of-type(4) {
  transition-delay: 0.9s;
}
.page-top .news_content_list.scrollAnimation-js .news_content_list_item:nth-of-type(5) {
  transition-delay: 1.2s;
}
.page-top .news_content_list.scrollAnimation-js .news_content_list_item:nth-of-type(6) {
  transition-delay: 1.5s;
}
.page-top .news_content_list.scrollAnimation-js-active .news_content_list_item {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.page-top .news_content_list_item {
  display: block;
  width: 100%;
  border-top: 1px solid #dbdbdb;
  position: relative;
  overflow: hidden;
}
.page-top .news_content_list_item:last-of-type {
  border-bottom: 1px solid #dbdbdb;
}
.page-top .news_content_list_item_inner {
  width: 100%;
  color: initial;
  text-decoration: none;
  padding: 30px 80px 30px 0;
}
@media screen and (min-width: 768px) {
  .page-top .news_content_list_item_inner {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .page-top .news_content_list_item_inner {
    display: block;
    padding: 19px 48px 21px 0;
  }
}
.page-top .news_content_list_item_inner::before {
  content: "";
  display: block;
  width: 10px;
  height: auto;
  aspect-ratio: 1/1;
  background-image: url(../img/common/arrow_b.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  transition: right 0.4s ease;
}
@media screen and (max-width: 767px) {
  .page-top .news_content_list_item_inner::before {
    width: 8px;
    right: 20px;
  }
}
.page-top .news_content_list_item_inner::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #15181f;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: right top;
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transform: scaleX(0);
  transition: transform 0.4s ease;
}
@media screen and (min-width: 768px) {
  .page-top .news_content_list_item_inner:hover::before {
    right: 20px;
  }
  .page-top .news_content_list_item_inner:hover::after {
    transform-origin: left top;
    transform: scaleX(1);
  }
  .page-top .news_content_list_item_inner:hover .news_content_list_item_date,
.page-top .news_content_list_item_inner:hover .news_content_list_item_category,
.page-top .news_content_list_item_inner:hover .news_content_list_item_title {
    transform: translateX(20px);
  }
}
.page-top .news_content_list_item_date {
  color: #9a9a9a;
  font-size: 1.6rem;
  line-height: 1.8125;
  margin-right: 60px;
  transition: transform 0.4s ease;
}
@media screen and (max-width: 767px) {
  .page-top .news_content_list_item_date {
    display: inline-block;
    font-size: 1.3rem;
    line-height: 1.2307692308;
    margin-right: 8px;
  }
}
.page-top .news_content_list_item_category {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 8px;
  margin-right: 20px;
  transition: transform 0.4s ease;
}
@media screen and (max-width: 767px) {
  .page-top .news_content_list_item_category {
    display: inline-flex;
    flex-direction: row;
    row-gap: 4px;
    column-gap: 4px;
    margin-right: 0;
  }
}
.page-top .news_content_list_item_category_item {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #214da6;
  border: 1px solid #214da6;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 4px 14px 3px;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .page-top .news_content_list_item_category_item {
    font-size: 1rem;
    line-height: 1.5;
    padding: 2px 11px 1px;
  }
}
.page-top .news_content_list_item_title {
  width: auto;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0;
  transition: transform 0.4s ease;
}
@media screen and (max-width: 767px) {
  .page-top .news_content_list_item_title {
    display: block;
    width: 100%;
    padding-top: 8px;
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}
.page-top .news_content .main_buttonLink {
  text-align: right;
  margin-top: 76px;
}
@media screen and (max-width: 767px) {
  .page-top .news_content .main_buttonLink {
    text-align: center;
    margin-top: 51px;
  }
}
.page-top .main_buttonLink_item-right {
  padding-right: 76px;
}
@media screen and (max-width: 767px) {
  .page-top .main_buttonLink_item-right {
    padding-right: 52px;
  }
}
.page-top .main_buttonLink_item-right::before {
  right: 23px;
}
@media screen and (max-width: 767px) {
  .page-top .main_buttonLink_item-right::before {
    right: 16px;
  }
}
.page-top .main_buttonLink_item-right::after {
  width: 52px;
}
@media screen and (max-width: 767px) {
  .page-top .main_buttonLink_item-right::after {
    width: 36px;
  }
}
@media screen and (min-width: 768px) {
  .page-top .main_buttonLink_item-right:hover::after {
    width: 62px;
    left: -3px;
    opacity: 1;
    border-width: 1px;
  }
}