@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  .mainvisual {
    display: block;
    width: 100%;
    height: 628px;
    background-image: url(../img/top/mainvisual/grid.svg);
    background-position: left top -7.2px;
    background-repeat: repeat;
    background-size: 30px auto;
    overflow: hidden;

    @include mixin.sp {
      height: 570px;
      background-position: left top;
      background-size: 26px auto;
    }

    &_inner {
      display: block;
      width: 100%;
      min-width: 1400px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);

      @include mixin.sp {
        min-width: 375px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 600px;
        height: auto;
        aspect-ratio: 1/0.867;
        position: absolute;
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;

        @media screen and (min-width: 1500px) {
          width: min(40vw, 840px);
        }

        @include mixin.sp {
          width: max(calc(61.4vw - 20px), 230px);
          max-width: 330px;
        }
      }

      &::before {
        background-image: url(../img/top/mainvisual/left.png);

        @include mixin.sp {
          left: -48px;
        }
      }

      &::after {
        background-image: url(../img/top/mainvisual/right.png);

        @include mixin.sp {
          right: -59px;
        }
      }
    }

    &_title {
      display: block;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 4rem;
      font-weight: 700;
      line-height: calc(60 / 40);
      letter-spacing: 0.044em;
      writing-mode: vertical-rl;
      margin: 0;

      @include mixin.sp {
        top: 35px;
        font-size: 2.4rem;
        line-height: calc(31 / 24);
        letter-spacing: 0.17em;
      }
    }

    &_text {
      display: block;
      position: absolute;
      left: 50%;
      bottom: 68px;
      transform: translateX(-50%);

      @include mixin.sp {
        width: calc(100% - 75px);
        min-width: 300px;
        bottom: 51px;
      }

      & > :first-child {
        margin-top: 0;
      }

      &_paragraph {
        margin: 5px 0 0;
        text-align: center;
        font-size: 2.2rem;
        line-height: calc(33 / 22);
        font-weight: 500;

        @include mixin.sp {
          font-size: 1.6rem;
          line-height: calc(30 / 16);
          margin: 0;
        }

        &_number {
          @include mixin.font-oswald;

          @include mixin.sp {
            font-size: 1.7rem;
          }
        }
      }
    }

    // Javascript
    &-js {
      .mainvisual_inner {
        &::before,
        &::after {
          opacity: 0;
          visibility: hidden;
          top: 60px;
          transition: all 0.4s ease;

          @include mixin.sp {
            top: 265px;
          }
        }

        &::before {
          left: -100px;

          @include mixin.sp {
            left: -78px;
          }
        }

        &::after {
          right: -100px;

          @include mixin.sp {
            right: -89px;
          }
        }
      }

      // Modifier
      &-active {
        .mainvisual_inner {
          &::before,
          &::after {
            top: 40px;
            opacity: 1;
            visibility: visible;

            @include mixin.sp {
              top: 245px;
            }
          }

          &::before {
            left: -50px;

            @include mixin.sp {
              left: -68px;
            }
          }

          &::after {
            right: -50px;

            @include mixin.sp {
              right: -59px;
            }
          }
        }
      }
    }
  }

  .service {
    display: block;
    width: 100%;
    padding: 130px 0 calc(141px + 120px);
    background-color: color.$grey-1;

    @include mixin.sp {
      padding: 72px 0;
    }

    &_title {
      display: flex;
      align-items: center;

      &_en {
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        @include mixin.font-oswald;
        font-weight: 400;
        color: color.$site-main;
        padding-right: 36px;
        position: relative;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(17 / 12);
          padding-right: 21px;
        }

        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background-color: color.$grey-3;
          transform: rotate(-68deg);
          position: absolute;
          top: 11.5px;
          right: 8px;

          @include mixin.sp {
            width: 10px;
            top: 8px;
            right: 5px;
          }
        }
      }

      &_ja {
        font-size: 1.6rem;
        line-height: calc(29 / 16);
        font-weight: 500;
        color: color.$grey-3;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(17 / 12);
        }
      }
    }

    &_head {
      display: flex;
      column-gap: 60px;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        row-gap: 24px;
      }

      &_title {
        font-size: 3.8rem;
        font-weight: 700;
        line-height: calc(60 / 38);
        margin: 0;

        @include mixin.pc {
          white-space: nowrap;
        }

        @include mixin.sp {
          font-size: 2.2rem;
          line-height: calc(33 / 22);
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(32 / 16);
        margin: 9px 0 0;

        @include mixin.sp {
          font-size: 1.3rem;
          line-height: calc(26 / 13);
          margin: 0;
        }
      }
    }

    small {
      font-size: 1.3rem;
      line-clamp: calc(18 / 13);

      @include mixin.sp {
        font-size: 1.3rem;
        line-clamp: calc(22 / 13);
      }
    }

    &_list {
      width: 100%;
      margin-top: 81px;

      @include mixin.pc {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        column-gap: 1px;
        row-gap: 1px;
      }

      @include mixin.sp {
        display: block;
        margin-top: 40px;
      }

      // Javascript
      &.scrollAnimation-js {
        .service_list_item {
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

          &:nth-of-type(2) {
            transition-delay: 0.3s;
          }
          &:nth-of-type(3) {
            transition-delay: 0.6s;
          }
          &:nth-of-type(4) {
            transition-delay: 0.9s;
          }
          &:nth-of-type(5) {
            transition-delay: 1.2s;
          }
          &:nth-of-type(6) {
            transition-delay: 1.5s;
          }
        }

        // Modifier
        &-active {
          .service_list_item {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }

      &_item {
        display: block;
        width: calc((100% / 3) - (2px / 3));
        background-color: color.$white;

        @include mixin.sp {
          width: 100%;
          margin-top: 30px;
        }

        &:nth-of-type(2n) {
          @include mixin.pc {
            // margin-top: 60px;
            position: relative;
            top: 60px;
          }
        }
        &:nth-of-type(3n) {
          @include mixin.pc {
            // margin-top: 120px;
            position: relative;
            top: 120px;
          }
        }

        &_image {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 1/0.694;

          @include mixin.sp {
            aspect-ratio: 1/0.55;
          }

          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &_text {
          display: block;
          width: 100%;
          padding: 28px 28px 50px;

          @include mixin.sp {
            padding: 20px 24px 26px;
          }

          &_title {
            font-size: 2.4rem;
            line-height: calc(36 / 24);
            margin: 0;

            @include mixin.sp {
              font-size: 2rem;
              line-height: calc(26 / 20);
            }
          }

          &_description {
            font-size: 1.6rem;
            line-height: calc(23 / 16);
            margin: 15px 0 0;

            @include mixin.sp {
              font-size: 1.3rem;
              line-height: calc(22 / 13);
              margin: 12px 0 0;
            }
          }

          &_list {
            font-size: 1.6rem;
            line-height: calc(23 / 16);
            margin: 20px 0 0;
            padding: 0 0 0 1.2em;

            @include mixin.sp {
              font-size: 1.3rem;
              line-height: calc(22 / 13);
              margin: 14px 0 0;
            }

            &_item {
              margin-top: 2px;
            }
          }
        }
      }
    }
  }

  .separator {
    display: block;
    width: 100%;
    height: auto;
    // aspect-ratio: 1/0.441;
    aspect-ratio: 1/0.412;
    position: relative;
    z-index: 2;

    @include mixin.sp {
      aspect-ratio: 1/0.66;
      background-image: url(../img/top/para.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &::after {
      content: "";
      display: block;
      width: 354px;
      height: auto;
      aspect-ratio: 1/1.167;
      background-image: url(../img/top/symbol.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      right: 59px;
      bottom: -271px;

      @include mixin.sp {
        width: 137px;
        right: 18px;
        bottom: -105px;
      }
    }

    &_inner {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;

      @include mixin.sp {
        display: none;
      }
    }

    &_image {
      display: block;
      width: 100%;
    }
  }

  .features {
    display: block;
    width: 100%;
    padding: 139px 0 136px;
    background-color: color.$grey-1;

    @include mixin.sp {
      padding: 102px 0 40px;
    }

    &_title {
      display: flex;
      align-items: center;

      &_en {
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        @include mixin.font-oswald;
        font-weight: 400;
        color: color.$site-main;
        padding-right: 36px;
        position: relative;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(17 / 12);
          padding-right: 21px;
        }

        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background-color: color.$grey-3;
          transform: rotate(-68deg);
          position: absolute;
          top: 11.5px;
          right: 8px;

          @include mixin.sp {
            width: 10px;
            top: 8px;
            right: 5px;
          }
        }
      }

      &_ja {
        font-size: 1.6rem;
        line-height: calc(29 / 16);
        font-weight: 500;
        color: color.$grey-3;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(17 / 12);
        }
      }
    }

    &_head {
      display: flex;
      column-gap: 60px;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        row-gap: 24px;
      }

      &_title {
        font-size: 3.8rem;
        font-weight: 700;
        line-height: calc(60 / 38);
        margin: 0;

        @include mixin.pc {
          white-space: nowrap;
        }

        @include mixin.sp {
          font-size: 2.2rem;
          line-height: calc(33 / 22);
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(32 / 16);
        margin: 9px 0 0;

        @include mixin.sp {
          font-size: 1.3rem;
          line-height: calc(26 / 13);
          margin: 0;
        }
      }
    }

    &_list {
      display: flex;
      flex-direction: column;
      row-gap: 28px;
      width: 100%;
      max-width: 1080px;
      margin: 100px auto 0;

      @include mixin.sp {
        margin-top: 30px;
        row-gap: 1px;
      }

      // Javascript
      &.scrollAnimation-js {
        .features_list_item {
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

          &:nth-of-type(2) {
            transition-delay: 0.3s;
          }
          &:nth-of-type(3) {
            transition-delay: 0.6s;
          }
          &:nth-of-type(4) {
            transition-delay: 0.9s;
          }
          &:nth-of-type(5) {
            transition-delay: 1.2s;
          }
          &:nth-of-type(6) {
            transition-delay: 1.5s;
          }
        }

        // Modifier
        &-active {
          .features_list_item {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }

      &_item {
        display: block;
        width: 100%;
        background-color: color.$white;
        border: 1px solid color.$grey-2;

        &_inner {
          display: flex;
          width: 100%;
          position: relative;
          color: inherit;
          text-decoration: none;

          &::before {
            content: "";
            display: block;
            width: 10px;
            height: auto;
            aspect-ratio: 1/1;
            background-image: url(../img/common/arrow_b.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            position: absolute;
            top: 50%;
            right: 63px;
            transform: translateY(-50%);

            @include mixin.sp {
              width: 8px;
              background-image: url(../img/common/arrow_w.svg);
              top: auto;
              right: 40px;
              bottom: 39px;
              z-index: 2;
              transform: none;
            }
          }

          &::after {
            content: "";
            display: block;
            width: 56px;
            height: auto;
            aspect-ratio: 1/1;
            border-radius: 100%;
            box-sizing: border-box;
            border: 2px solid color.$grey-2;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            transition: all 0.3s ease;

            @include mixin.sp {
              width: 40px;
              top: auto;
              right: 24px;
              bottom: 23px;
              z-index: 2;
              transform: none;
            }
          }

          @include mixin.hover {
            &::after {
              width: 62px;
              right: 37px;
              border-width: 1px;
              border-color: color.$site-main;
            }
          }
        }

        &_image {
          display: block;
          width: 360px;
          height: auto;
          aspect-ratio: 1/0.667;

          @include mixin.sp {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }

          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &_text {
          display: block;
          width: calc(100% - 360px);
          padding: 46px 127px 53px 50px;

          @include mixin.sp {
            width: 100%;
            padding: 56px 44px 68px;
            position: relative;
            z-index: 1;
            color: color.$white;
          }

          &::after {
            @include mixin.sp {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: #16284d;
              opacity: 0.5;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
            }
          }

          &_head {
            display: flex;
            align-items: flex-start;
            column-gap: 8px;

            &_number {
              display: block;
              @include mixin.font-oswald;
              font-size: 1.6rem;
              line-height: calc(24 / 16);
              font-weight: 400;

              @include mixin.pc {
                color: color.$site-main;
              }

              @include mixin.sp {
                font-size: 1.2rem;
                line-height: calc(18 / 12);
              }
            }

            &_title {
              font-size: 2.8rem;
              line-height: calc(41 / 28);
              margin: 0;
              font-weight: 500;

              @include mixin.sp {
                font-size: 2rem;
                line-height: calc(26 / 20);
              }
            }
          }

          &_description {
            font-size: 1.6rem;
            line-height: calc(28 / 16);
            margin: 16px 0 0;

            @include mixin.sp {
              font-size: 1.3rem;
              line-height: calc(22 / 13);
              margin: 12px 0 0;
            }
          }
        }
      }
    }

    .main_buttonLink {
      text-align: center;
      margin-top: 76px;

      @include mixin.sp {
        margin-top: 51px;
      }
    }
  }

  .works {
    display: block;
    width: 100%;
    padding: 120px 0 98px;

    @include mixin.sp {
      padding: 92px 0 50px;
    }

    &_title {
      display: flex;
      align-items: center;

      &_en {
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        @include mixin.font-oswald;
        font-weight: 400;
        color: color.$site-main;
        padding-right: 36px;
        position: relative;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(17 / 12);
          padding-right: 21px;
        }

        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background-color: color.$grey-3;
          transform: rotate(-68deg);
          position: absolute;
          top: 11.5px;
          right: 8px;

          @include mixin.sp {
            width: 10px;
            top: 8px;
            right: 5px;
          }
        }
      }

      &_ja {
        font-size: 1.6rem;
        line-height: calc(29 / 16);
        font-weight: 500;
        color: color.$grey-3;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(17 / 12);
        }
      }
    }

    &_head {
      display: flex;
      column-gap: 60px;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        row-gap: 24px;
      }

      &_title {
        font-size: 3.8rem;
        font-weight: 700;
        line-height: calc(60 / 38);
        margin: 0;

        @include mixin.pc {
          white-space: nowrap;
        }

        @include mixin.sp {
          font-size: 2.2rem;
          line-height: calc(33 / 22);
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(32 / 16);
        margin: 9px 0 0;

        @include mixin.sp {
          font-size: 1.3rem;
          line-height: calc(26 / 13);
          margin: 0;
        }
      }
    }

    &_kind {
      display: flex;
      width: 100%;
      border: 1px solid color.$grey-2;
      padding: 24px 39px 26px 39px;
      margin-top: 80px;

      @include mixin.sp {
        flex-direction: column;
        padding: 19px 15px;
        margin-top: 40px;
      }

      // Javascript
      &.scrollAnimation-js,
      &.scrollAnimation-js + .main_worksList {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
        transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

        // Modifier
        &-active,
        &-active + .main_worksList {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
      &.scrollAnimation-js + .main_worksList {
        transition-delay: 0.3s;
      }

      &_head {
        display: flex;
        align-items: center;
        width: 97px;

        @include mixin.pc {
          border-right: 1px solid color.$grey-2;
        }

        @include mixin.sp {
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid color.$grey-2;
        }

        &_title {
          font-size: 1.4rem;
          line-height: calc(29 / 14);
          font-weight: 500;
          margin: 0;

          @include mixin.sp {
            font-size: 1.2rem;
            line-height: calc(22 / 12);
          }
        }
      }

      &_content {
        display: block;
        width: calc(100% - 97px);

        @include mixin.sp {
          width: 100%;
          padding-top: 12px;
        }

        &_list {
          display: flex;
          flex-wrap: wrap;
          column-gap: 40px;
          margin: 0;
          padding: 0 0 0 40px;
          list-style: none;

          @include mixin.sp {
            flex-direction: column;
            column-gap: 0;
            row-gap: 4px;
            padding: 0;
          }

          &_item {
            display: flex;
            column-gap: 20px;

            @include mixin.sp {
              width: 100%;
              flex-direction: column;
              column-gap: 0;
              row-gap: 0;
            }

            &:nth-of-type(odd) {
              @include mixin.pc {
                width: calc(50% - 60px);
              }

              .works_kind_content_list_item_title {
                @include mixin.pc {
                  min-width: 82px;
                }
              }
            }
            &:nth-of-type(even) {
              @include mixin.pc {
                width: calc(50% + 20px);
              }

              @include mixin.sp {
                order: 2;
              }

              .works_kind_content_list_item_title {
                @include mixin.pc {
                  min-width: 53px;
                }
              }
            }

            &_title {
              font-size: 1.4rem;
              line-height: calc(24 / 14);
              font-weight: 500;
              margin: 0;
              padding: 0 0 0 9px;
              white-space: nowrap;
              position: relative;

              @include mixin.sp {
                font-size: 1.2rem;
                line-height: calc(18 / 12);
                padding-left: 12px;
              }

              &::before {
                content: "";
                display: block;
                width: 4px;
                height: auto;
                aspect-ratio: 1/1;
                background-color: color.$site-write;
                position: absolute;
                top: 10px;
                left: 0;

                @include mixin.sp {
                  top: 6px;
                }
              }
            }

            &_content {
              display: block;
              font-size: 1.4rem;
              line-height: calc(24 / 14);

              @include mixin.sp {
                font-size: 1.2rem;
                line-height: calc(18 / 12);
                padding-left: 12px;
              }
            }
          }
        }
      }
    }

    .main_worksList {
      margin-top: 60px;

      @include mixin.sp {
        margin-top: 40px;
      }
    }

    .main_buttonLink {
      text-align: center;
      margin-top: 107px;

      @include mixin.sp {
        margin-top: 51px;
      }
    }
  }

  .news {
    display: block;
    width: 100%;
    padding: 98px 0 0;

    @include mixin.sp {
      padding: 50px 0 0;
    }

    &_inner {
      display: flex;
      width: 100%;

      @include mixin.sp {
        flex-direction: column;
        row-gap: 40px;
      }
    }

    &_head {
      display: block;
      width: 360px;

      @include mixin.sp {
        width: 100%;
      }

      &_en {
        display: block;
        @include mixin.font-oswald;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        margin-bottom: 20px;
        color: color.$site-main;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(18 / 12);
          margin-bottom: 2px;
        }
      }

      &_ja {
        font-size: 3.6rem;
        line-height: calc(36 / 36);
        font-weight: 700;
        margin: 0;

        @include mixin.sp {
          font-size: 2rem;
          line-height: calc(29 / 20);
        }
      }
    }

    &_content {
      display: block;
      width: calc(100% - 360px);

      @include mixin.sp {
        width: 100%;
      }

      &_list {
        display: block;
        width: 100%;

        // Javascript
        &.scrollAnimation-js {
          .news_content_list_item {
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
            transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

            &:nth-of-type(2) {
              transition-delay: 0.3s;
            }
            &:nth-of-type(3) {
              transition-delay: 0.6s;
            }
            &:nth-of-type(4) {
              transition-delay: 0.9s;
            }
            &:nth-of-type(5) {
              transition-delay: 1.2s;
            }
            &:nth-of-type(6) {
              transition-delay: 1.5s;
            }
          }

          // Modifier
          &-active {
            .news_content_list_item {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        }

        &_item {
          display: block;
          width: 100%;
          border-top: 1px solid color.$grey-2;
          position: relative;
          overflow: hidden;

          &:last-of-type {
            border-bottom: 1px solid color.$grey-2;
          }

          &_inner {
            width: 100%;
            color: initial;
            text-decoration: none;
            padding: 30px 80px 30px 0;

            @include mixin.pc {
              display: flex;
              align-items: center;
            }

            @include mixin.sp {
              display: block;
              padding: 19px 48px 21px 0;
            }

            &::before {
              content: "";
              display: block;
              width: 10px;
              height: auto;
              aspect-ratio: 1/1;
              background-image: url(../img/common/arrow_b.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: auto 100%;
              position: absolute;
              top: 50%;
              right: 40px;
              transform: translateY(-50%);
              transition: right 0.4s ease;

              @include mixin.sp {
                width: 8px;
                right: 20px;
              }
            }

            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background-color: color.$site-write;
              position: absolute;
              top: 0;
              left: 0;
              transform-origin: right top;
              transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
              transform: scaleX(0);
              transition: transform 0.4s ease;
            }

            @include mixin.hover {
              &::before {
                right: 20px;
              }

              &::after {
                transform-origin: left top;
                transform: scaleX(1);
              }

              .news_content_list_item_date,
              .news_content_list_item_category,
              .news_content_list_item_title {
                transform: translateX(20px);
              }
            }
          }

          &_date {
            color: color.$grey-3;
            font-size: 1.6rem;
            line-height: calc(29 / 16);
            margin-right: 60px;
            transition: transform 0.4s ease;

            @include mixin.sp {
              display: inline-block;
              font-size: 1.3rem;
              line-height: calc(16 / 13);
              margin-right: 8px;
            }
          }

          &_category {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            row-gap: 8px;
            margin-right: 20px;
            transition: transform 0.4s ease;

            @include mixin.sp {
              display: inline-flex;
              flex-direction: row;
              row-gap: 4px;
              column-gap: 4px;
              margin-right: 0;
            }

            &_item {
              font-size: 1.2rem;
              line-height: calc(18 / 12);
              color: color.$site-main;
              border: 1px solid color.$site-main;
              background-color: color.$white;
              border-radius: 30px;
              padding: 4px 14px 3px;
              white-space: nowrap;

              @include mixin.sp {
                font-size: 1rem;
                line-height: calc(15 / 10);
                padding: 2px 11px 1px;
              }
            }
          }

          &_title {
            width: auto;
            font-size: 1.6rem;
            line-height: calc(24 / 16);
            margin: 0;
            transition: transform 0.4s ease;

            @include mixin.sp {
              display: block;
              width: 100%;
              padding-top: 8px;
              font-size: 1.3rem;
              line-height: calc(22 / 13);
            }
          }
        }
      }

      .main_buttonLink {
        text-align: right;
        margin-top: 76px;

        @include mixin.sp {
          text-align: center;
          margin-top: 51px;
        }
      }
    }
  }

  .main_buttonLink {
    &_item {
      // Modifier
      &-right {
        padding-right: 76px;

        @include mixin.sp {
          padding-right: 52px;
        }

        &::before {
          right: 23px;

          @include mixin.sp {
            right: 16px;
          }
        }
        &::after {
          width: 52px;

          @include mixin.sp {
            width: 36px;
          }
        }

        @include mixin.hover {
          &::after {
            width: 62px;
            left: -3px;
            opacity: 1;
            border-width: 1px;
          }
        }
      }
    }
  }
}
